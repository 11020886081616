@test: red;

.emmen-theme {
  --brand-color: #b8215f;
  --filter-color: #ff4081;
  --darker-outline-color: #af1a7c;
}

.coevorden-theme {
  --brand-color: green;
  --filter-color: green;
  --darker-outline-color: green;
}
