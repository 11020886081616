html {
  body {
    background: transparent;

    #application {
      min-height: 100%;

      [routerLink] {
        cursor: pointer;
      }

      .field-focus {
        outline-width: 2px;
        outline-color: red;
      }

      .hidden {
        display: none !important;
      }

      .href {
        text-decoration: underline;
        color: #0d6efd;

        &:hover {
          color: #0a58ca;
          cursor: pointer;
        }
      }

      .borderless {
        .mat-form-field-infix {
          border-top: 0px !important;
        }
      }
    }

    .dialog {
      .title {
        margin-bottom: 12px;
        border-bottom: 1px solid #e0e0e0;
        display: block;
        padding: 5px;
        font-weight: 600;
      }

      button {
        background: #5273ff;
        color: white;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
